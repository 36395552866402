import classNames from 'classnames';
import './_heading3.scss';
import '../_heading.scss';

interface IHeading3Props
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  formatClassName?: string;
}

export function Heading3(props: IHeading3Props) {
  if (!props || !props.children) {
    return null;
  }
  const { formatClassName = 'default', className, children, ...heading3Props } = props;

  const baseClassName = 'heading heading3';
  const componentClassName = classNames(baseClassName, formatClassName, className);

  return (
    <h3 {...heading3Props} className={componentClassName}>
      {children}
    </h3>
  );
}
