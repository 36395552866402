import { ScrollControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Page } from 'components';
import { Loading, Overlay, SceneToonWorld } from 'features';
import { Suspense } from 'react';
import data from 'static/data';
import './_home-page.scss';

export function HomePage() {
  const baseClassName = 'home-page';

  return (
    <Page className={baseClassName}>
      <Canvas>
        <Suspense fallback={<Loading />}>
          <ScrollControls pages={data.pages} damping={0.35}>
            <SceneToonWorld />
            <Overlay />
          </ScrollControls>
        </Suspense>
      </Canvas>
    </Page>
  );
}
