export const MIN_VALUE = -Math.PI * 2;
export const MAX_VALUE = Math.PI * 2;
export const STEP_VALUE = 0.01;
export const GLTF_PATH = './models/sceneToon.glb';

export const LEVA_CAMERA_POSITION = {
  x: {
    value: -1.45,
    min: MIN_VALUE,
    max: MAX_VALUE,
    step: STEP_VALUE,
  },
  y: { value: -0.19, min: MIN_VALUE, max: MAX_VALUE, step: STEP_VALUE },
  z: { value: 1.07, min: MIN_VALUE, max: MAX_VALUE, step: STEP_VALUE },
};

export const LEVA_GROUP_ROTATION = {
  x: { value: -0.07, min: MIN_VALUE, max: MAX_VALUE, step: STEP_VALUE },
  y: { value: 1.3, min: MIN_VALUE, max: MAX_VALUE, step: STEP_VALUE },
  z: { value: 0.06, min: MIN_VALUE, max: MAX_VALUE, step: STEP_VALUE },
};
