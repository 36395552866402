import classNames from 'classnames';
import { Alignment } from 'static/constants';
import './_overlay-section.scss';

interface OverlaySectionProps {
  opacity?: number;
  position?: Alignment;
  children?: any;
}

export const OverlaySection = (props: OverlaySectionProps) => {
  const baseClassName = 'overlay-section';
  const componentClassName = classNames(baseClassName, props.position);

  return (
    <div
      className={componentClassName}
      style={{
        opacity: props.opacity,
      }}
    >
      <div className={`${baseClassName}__wrapper`}>{props.children}</div>
    </div>
  );
};
