import classNames from 'classnames';
import './_heading2.scss';
import '../_heading.scss';

interface IHeading2Props
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
  formatClassName?: string;
}

export function Heading2(props: IHeading2Props) {
  if (!props || !props.children) {
    return null;
  }
  const { formatClassName = 'default', className, children, ...heading2Props } = props;

  const baseClassName = 'heading heading2';
  const componentClassName = classNames(baseClassName, formatClassName, className);

  return (
    <h2 {...heading2Props} className={componentClassName}>
      {children}
    </h2>
  );
}
