import { Html, useProgress } from '@react-three/drei';
import './_loading.scss';

export function Loading() {
  const { progress } = useProgress();

  return (
    <Html center>
      <h2 className={'loading'}>
        <span>{progress.toFixed(0)}</span>
        <span> %</span>
      </h2>
    </Html>
  );
}
