import classNames from 'classnames';
import { ISvgProps, SvgIcons } from './interfaces';
import * as SvgComponents from './index';
import './_svg-icon.scss';

interface ISvgIconProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {
  icon: SvgIcons;
  svgProps?: ISvgProps;
  width?: number;
}

const resolveSvgByType = (name: SvgIcons) => {
  if (!SvgComponents[name]) {
    return null;
  }
  return SvgComponents[name];
};

export function SvgIcon(props: ISvgIconProps) {
  const { className, icon, svgProps, width = 100 } = props;

  const SvgIcon = resolveSvgByType(icon);
  if (!SvgIcon) {
    return null;
  }

  const formattedClassName = classNames('svg-icon', className);

  return (
    <div className={formattedClassName} style={{ width: width }}>
      <SvgIcon {...svgProps} />
    </div>
  );
}
