export enum Pages {
  Home = '/',
}

export enum Alignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

export enum Breakpoints {
  Medium = '680px',
  Large = '1280px',
  Huge = '1600px',
}

export const POSITION_Z = 75;
