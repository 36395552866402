import classNames from 'classnames';
import './_page.scss';

interface IPageProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> {}

export function Page(props: IPageProps) {
  const { className, children } = props;

  const baseClassName = 'page';
  const componentClassName = classNames(baseClassName, className);

  return <section className={componentClassName}>{children}</section>;
}
