import { Layout } from 'components';
import { HomePage } from 'pages';
import { Route, Routes } from 'react-router-dom';
import { Pages } from 'static/constants';
import './App.scss';

function App() {
  return (
    <Layout>
      <Routes>
        <Route path={Pages.Home} element={<HomePage />} />
        <Route path={'*'} element={<HomePage />} />
      </Routes>
    </Layout>
  );
}

export default App;
