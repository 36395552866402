import { Scroll, useScroll } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { Anchor, Heading2, Heading3 } from 'components';
import { useState } from 'react';
import { OverlaySection } from 'features';
import { Alignment } from 'static/constants';
import data from 'static/data';
import './_overlay.scss';

export function Overlay() {
  const scroll = useScroll();

  const [opacityFirstSection, setOpacityFirstSection] = useState(1);
  const [opacitySecondSection, setOpacitySecondSection] = useState(0);
  const [opacityThirdSection, setOpacityThirdSection] = useState(0);
  const [opacityFourthSection, setOpacityFourthSection] = useState(0);
  const [opacityFifthSection, setOpacityFifthSection] = useState(0);
  const [opacityLastSection, setOpacityLastSection] = useState(0);

  useFrame(() => {
    setOpacityFirstSection(1 - scroll.range(0.5 / data.pages, 0.2 / data.pages));
    setOpacitySecondSection(scroll.range(1 / data.pages, 0.5 / data.pages));
    setOpacityThirdSection(scroll.range(2 / data.pages, 0.5 / data.pages));
    setOpacityFourthSection(scroll.range(3 / data.pages, 0.5 / data.pages));
    setOpacityFifthSection(scroll.range(4 / data.pages, 0.5 / data.pages));
    setOpacityLastSection(scroll.range(5 / data.pages, 0.5 / data.pages));
  });

  return (
    <Scroll html>
      <div className={'overlay-content'}>
        <OverlaySection opacity={opacityFirstSection} position={Alignment.Center}>
          <Heading3>Hi! I am</Heading3>
          <Heading3>Jose Pedro Iglesias</Heading3>
          <Heading2>Senior</Heading2>
          <Heading2>Frontend</Heading2>
          <Heading2>Developer</Heading2>
        </OverlaySection>
        <OverlaySection opacity={opacitySecondSection} position={Alignment.Bottom}>
          <Heading2>{`Yeah`}</Heading2>
          <Heading2>{`that's me!`}</Heading2>
        </OverlaySection>
        <OverlaySection opacity={opacityThirdSection} position={Alignment.Bottom}>
          <Heading2>{'a passionate and creative soul'}</Heading2>
        </OverlaySection>
        <OverlaySection opacity={opacityFourthSection} position={Alignment.Bottom}>
          <Heading2>{'with more than 10 years in the IT industry'}</Heading2>
        </OverlaySection>
        <OverlaySection opacity={opacityFifthSection} position={Alignment.Bottom}>
          <Heading2>{`always ready for a new challenge!`}</Heading2>
        </OverlaySection>
        <OverlaySection opacity={opacityLastSection} position={Alignment.Center}>
          <Heading2>{`To know more about me`}</Heading2>
          <Anchor
            className={'linkedin'}
            href={'https://www.linkedin.com/in/josepedroiglesias'}
            target={'_blank'}
            rel='noreferrer'
          >
            Linkedin
          </Anchor>
          <p className={'license'}>
            {'Blender model made by Luyssport. The original model can be found '}
            <a href={'https://sketchfab.com/luyssport'} target={'_blank'} rel='noreferrer'>
              here
            </a>
          </p>
        </OverlaySection>
      </div>
    </Scroll>
  );
}
