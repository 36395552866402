import { GLTF_PATH, LEVA_CAMERA_POSITION, LEVA_GROUP_ROTATION } from 'static/scene';
import { useEffect, useRef } from 'react';
import { PerspectiveCamera, useGLTF, useScroll } from '@react-three/drei';
import { PerspectiveCameraProps, useFrame } from '@react-three/fiber';
import { Group } from 'three';
import gsap from 'gsap';
import { Leva, useControls } from 'leva';

export function SceneToonWorld() {
  const { nodes, materials }: any = useGLTF(GLTF_PATH);

  const scroll = useScroll();
  const tl = useRef<any>(null);
  const camRef = useRef<PerspectiveCameraProps>();
  const groupRef = useRef<Group>(null);

  const position = useControls('Camera position', LEVA_CAMERA_POSITION);
  const rotation = useControls('Group rotation', LEVA_GROUP_ROTATION);

  useFrame(() => {
    tl?.current?.seek(scroll.offset * tl?.current?.duration());
  });

  useEffect(() => {
    tl.current = gsap.timeline();

    tl.current
      .to(camRef?.current?.position, {
        duration: 0.7,
        x: -1.45,
        y: -0.19,
        z: 1.07,
      })
      .to(
        camRef?.current?.position,
        {
          duration: 0.7,
          x: -0.98,
          y: -0.04,
          z: 2.07,
        },
        1,
      )
      .to(
        groupRef?.current?.rotation,
        {
          duration: 0.7,
          x: -0.06,
          y: 3.14,
          z: 0.27,
        },
        1,
      )
      .to(
        camRef?.current?.position,
        {
          duration: 0.7,
          x: -1.1,
          y: 0.14,
          z: -0.05,
        },
        2,
      )
      .to(
        camRef?.current?.position,
        {
          duration: 0.7,
          x: -1.04,
          y: -0.07,
          z: -2.01,
        },
        3,
      )
      .to(
        groupRef?.current?.rotation,
        {
          duration: 0.7,
          x: -0.06,
          y: 3.14,
          z: 0.09,
        },
        3,
      )
      .to(
        camRef?.current?.position,
        {
          duration: 0.7,
          x: 1.08,
          y: 0.1,
          z: 1.8,
        },
        4,
      )
      .to(
        groupRef?.current?.rotation,
        {
          duration: 0.7,
          x: -0.01,
          y: 0.05,
          z: 0.26,
        },
        4,
      )
      .to(
        camRef?.current?.position,
        {
          duration: 0.7,
          x: 4.49,
          y: 6.28,
          z: 2.95,
        },
        5,
      )
      .to(
        groupRef?.current?.rotation,
        {
          duration: 0.7,
          x: 0.04,
          y: 0.61,
          z: -0.58,
        },
        5,
      );
  }, []);

  return (
    <>
      <Leva hidden />
      <PerspectiveCamera
        ref={camRef}
        fov={75}
        position={[position.x, position.y, position.z]}
        makeDefault
      />
      <group ref={groupRef} dispose={null} rotation={[rotation.x, rotation.y, rotation.z]}>
        <group rotation={[-Math.PI / 2, 0, 0]} scale={0.4}>
          <mesh
            geometry={nodes.character_0.geometry}
            material={materials.roof}
            position={[2.11, 4.37, -1.14]}
          />
          <mesh
            geometry={nodes.sky_0.geometry}
            material={materials.material_10}
            rotation={[0, 0, -0.1]}
            scale={0.55}
          />
          <mesh
            geometry={nodes.ground_0.geometry}
            material={materials.ground}
            position={[1.98, -3.33, 0]}
          />
          <mesh
            geometry={nodes.tree_leaves_0.geometry}
            material={materials.tree_leaves}
            position={[1.98, -3.33, 0]}
          />
          <mesh
            geometry={nodes.tree_leaves_inv_0.geometry}
            material={materials.tree_leaves}
            position={[1.98, -3.33, 0]}
          />
          <mesh
            geometry={nodes.roof_0.geometry}
            material={materials.roof}
            position={[1.98, -3.33, 0]}
          />
          <mesh
            geometry={nodes.madera_0.geometry}
            material={materials.madera}
            position={[1.98, -3.33, 0]}
          />
          <mesh
            geometry={nodes.piedras_0.geometry}
            material={materials.piedras}
            position={[1.98, -3.33, 0]}
          />
          <mesh
            geometry={nodes.dec_0.geometry}
            material={materials.material}
            position={[1.98, -3.33, 0]}
          />
          <mesh
            geometry={nodes.outline_0.geometry}
            material={materials.outline}
            position={[1.98, -3.33, 0]}
          />
          <mesh
            geometry={nodes.monster_001_0.geometry}
            material={materials.monster_001}
            rotation={[Math.PI / 2, 0, 0]}
          />
          <mesh
            geometry={nodes.monster_0.geometry}
            material={materials.monster}
            rotation={[Math.PI / 2, 0, 0]}
          />
          <mesh
            geometry={nodes.eyes002_0.geometry}
            material={materials.eyes002}
            rotation={[Math.PI / 2, 0, 0]}
          />
        </group>
      </group>
    </>
  );
}

useGLTF.preload(GLTF_PATH);
