import classNames from 'classnames';
import './_anchor.scss';

interface IAnchorProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  formatClassName?: string;
  href?: string;
  target?: string;
}

export function Anchor(props: IAnchorProps) {
  if (!props || !props.href || !props.children) {
    return null;
  }
  const { formatClassName = 'default', className, href, target, children } = props;

  const baseClassName = 'anchor';
  const componentClassName = classNames(baseClassName, formatClassName, className, target);

  return (
    <a className={componentClassName} href={href} target={target}>
      {children}
    </a>
  );
}
